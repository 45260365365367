import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../../store'

interface IAssetAllocationSliceState {
  assetAllocationType?: string
}

const initialState: IAssetAllocationSliceState = {
  assetAllocationType: 'Asset'
}

const Slice = createSlice({
  name: '@shared/assetAllocation',
  initialState,
  reducers: {
    SetAssetAllocationType: (state, action: PayloadAction<string>) => {
      return { ...state, assetAllocationType: action.payload }
    }
  }
})
export const { SetAssetAllocationType } = Slice.actions
export const getAssetAllocationType = (state: RootState) => state.shared.assetAllocation.assetAllocationType
export default Slice.reducer
