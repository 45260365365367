import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../../store'
import { PageCustomization } from '../shared/api/types/MarketDataPageCustomization.type'

interface ILoginSliceState {
  pagePreferences?: PageCustomization[]
  companyoverview?: {
    chartRange?: string
    previousIdentifier?: string
    previousIdentifierType?: string
  }
}

const initialState: ILoginSliceState = {}

const Slice = createSlice({
  name: '@module/markets',
  initialState,
  reducers: {
    SetMarketsPagePreference: (state, action: PayloadAction<PageCustomization[] | undefined>) => {
      return { ...state, pagePreferences: action.payload }
    },
    UpdateMarketsPagePreference: (state, action: PayloadAction<PageCustomization>) => {
      const pagePreferences = state.pagePreferences
      if (pagePreferences) {
        const index = pagePreferences.findIndex((pagePreference) => pagePreference.page === action.payload.page)
        if (index !== -1) {
          pagePreferences.splice(index, 1, action.payload)
        }
      }
      return state
    },
    SetCompanyOverviewChartRange: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, companyoverview: { ...state.companyoverview, chartRange: action.payload } }
    },
    SetCompanyOverviewPreviousIdentifier: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, companyoverview: { ...state.companyoverview, previousIdentifier: action.payload } }
    },
    SetCompanyOverviewPreviousIdentifierType: (state, action: PayloadAction<string | undefined>) => {
      return { ...state, companyoverview: { ...state.companyoverview, previousIdentifierType: action.payload } }
    }
  }
})

export const {
  SetMarketsPagePreference,
  UpdateMarketsPagePreference,
  SetCompanyOverviewChartRange,
  SetCompanyOverviewPreviousIdentifier,
  SetCompanyOverviewPreviousIdentifierType
} = Slice.actions
export const getMarketsPagePreferences = (state: RootState) => state.modules.markets.market.pagePreferences
export const getCompanyOverviewChartRange = (state: RootState) =>
  state.modules.markets.market.companyoverview?.chartRange
export const getCompanyOverviewPreviousIdentifier = (state: RootState) =>
  state.modules.markets.market.companyoverview?.previousIdentifier
export const getCompanyOverviewPreviousIdentifierType = (state: RootState) =>
  state.modules.markets.market.companyoverview?.previousIdentifierType
export default Slice.reducer
