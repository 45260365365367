import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WatchList } from '../api/types/type'
import { RootState } from '../../../../store'

interface IWatchListSliceState {
  selectedWatchList?: WatchList
}

const initialState: IWatchListSliceState = {}

const Slice = createSlice({
  name: '@module/markets/watchlist',
  initialState,
  reducers: {
    SetWatchList: (state, action: PayloadAction<WatchList | undefined>) => {
      return { ...state, selectedWatchList: action.payload }
    }
  }
})
export const getSelectedWatchList = (state: RootState) => state.modules.markets.watchlist.selectedWatchList
export const { SetWatchList } = Slice.actions
export default Slice.reducer
